import React from 'react';
import { FiX } from  'react-icons/fi'
import useFetch from '../hooks/useFetch';
import {Link} from 'react-router-dom' ; 

const CategoryNavMobile = ({setCatnavMobile}) => {

  const { data } = useFetch('/categories');
  return (
    <div className='w-full h-full bg-primary dark:bg-notdarkblue p-8'>
     {/* close icon */}
     <div 
      onClick={ () => setCatnavMobile(false)}
     className='flex justify-end mb-8 cursor-pointer '> 
      <FiX  className='text-3xl dark:text-black'/>
     </div>
     <div className='flex flex-col gap-y-5 p-6'>
              {data?.map( (category) => {
                 return <Link to={`/products/${category.id}`} key={category.id}
                  className='cursor-pointer uppercase border-gray-300 border-b pb-2  dark:border-accent
                   dark:text-slate-950 font-bold pr-1'> {category.name}
                   </Link>
              })}
             </div>
    </div>
  )
   ;
};

export default CategoryNavMobile;
