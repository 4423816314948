import React from 'react';

import { useLocation } from 'react-router-dom';
import useFetch from '../hooks/useFetch';

import CategoryNav from '../components/CategoryNav';
import Product from '../components/Product' ;

const Search = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('query');
  console.log(searchTerm);

  // get products based search terms 
  const {data} = useFetch(`/serviceSearch?name=${searchTerm}`)

  return (   

    <div className="container mx-auto lg:pt-0">


<div className="mx-auto mb-10">
    <div className="px-4 lg:px-24 flex flex-wrap items-right justify-right">
      {data?.map((item, index) => (
        <div key={item.id} className={`w-1/3 sm:w-1/3 md:w-1/4 lg:w-1/5`}>
          <Product item={item} />
        </div>
      ))}
    </div>
  </div>

    </div>
    
  
  )
};

export default Search;
