import React from 'react';

//use Fetch Hook

import LatestProducts from '../components/LatestProducts.js'
import HomeProducts from '../components/HomeProducts.js'
import Hero from '../components/Hero.js'

const Home = () => {
 

  return (
    <section className='m-auto'>
              <Hero /> 
              <HomeProducts /> 
    </section>
  );
        
  
};

export default Home;
