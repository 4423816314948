import React from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../hooks/useFetch';
import Quta from '../components/Quta';

const Qutas = () => {

  const { id } = useParams();
  const { data } = useFetch(`/qutas/${id}`) ;
 // console.log(id + ' the id is ');
   return (   

    <div className="container mx-auto lg:pt-0">
      
       {data && data.length > 0 && 
         <div className='font-bold text-3xl'>
            <h1 className="text-center  dark:text-notdark">اختر باقة من باقات</h1>
            <h1 className="text-center mb-5 dark:text-notdark" > {data[0].service.name} </h1>
          </div>
          
       }

<div className="mx-auto mb-10">
    <div className="px-4 lg:px-24 flex flex-wrap items-right justify-right">
      {data?.map((item, index) => (
        <div key={item.id} className={`w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/5`}>
          <Quta key={item.id} item={item} />
        </div>
      ))}
    </div>
  </div>
     

</div>
    
  
  )

 


  
  
};
export default Qutas;
