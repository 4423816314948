import React, { createContext, useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';

export const SettingsContext = createContext();

 const SettingsProvider = ({ children }) => {
  const { data } = useFetch('/settings');
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    if (data) {
      setSettings(data);
    }
  }, [data]);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
