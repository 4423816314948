import React from 'react';
import './LoadingIndicator.css';

const LoadingIndicator = () => {
  const colors = ['#F94144', '#F3722C', '#F9C74F', '#90BE6D', '#43AA8B', '#577590'];
  
  return (
    <div className="loading-cross">
      <div className="loading-row">
        <div className="loading-square square1" style={{ backgroundColor: colors[0] }}></div>
        <div className="loading-square square2" style={{ backgroundColor: colors[1] }}></div>
      </div>
      <div className="loading-row">
        <div className="loading-square square3" style={{ backgroundColor: colors[2] }}></div>
        <div className="loading-square square4" style={{ backgroundColor: colors[3] }}></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;