import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import Search from './pages/Search';
import Header from './components/Header';
import Footer from './components/Footer';
import Qutas from './pages/Qutas';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIndicator from './LoadingIndicator';

const App = () => {

  const isLoading =false ;
  /*
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Simulate an asynchronous operation
      await new Promise(resolve => setTimeout(resolve, 500));

      // Fetch your data here
      // Example:
      // const response = await fetch('https://api.example.com/data');
      // const data = await response.json();

      setIsLoading(false);
    };

    fetchData();
  }, []);

  */

  return (
    <div className="dark:bg-notdarkblue">
      <ToastContainer />
      <Router>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products/:id" element={<Products />} />
              <Route path="/quotes/:id" element={<Qutas />} />
              <Route path="/product/:id" element={<ProductDetails />} />
              <Route path="/search" element={<Search />} />
            </Routes>
            <Footer />
          </>
        )}
      </Router>
    </div>
  );
};

export default App;
