
import React, { useContext ,useState, useEffect , useRef} from 'react';

import useFetch from '../hooks/useFetch';
import LoadingIndicator from '../LoadingIndicator';
import CategoryNav from '../components/CategoryNav' 
import MainSlider from '../components/MainSlider'

import PromoImg1 from '../img/promo_img1.png'
import PromoImg2 from '../img/promo_img2.png'
import { SettingsContext } from '../context/SettingsContext';
import ScrollingText from './Scrollingtext';
const Hero = () => {

  const settings = useContext(SettingsContext);

  const { data } = useFetch('/news');
  const news = data?.map(item => item.text);

  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

 
  const containerRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && textRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const textWidth = textRef.current.offsetWidth;

      if (textWidth > containerWidth) {
        const distance = textWidth - containerWidth;
        const duration = distance * 30; // Adjust the speed as needed

        const scroll = () => {
          textRef.current.style.transform = `translateX(-${distance}px)`;
          textRef.current.style.transitionDuration = `${duration}ms`;

          setTimeout(() => {
            textRef.current.style.transform = 'translateX(0)';
            textRef.current.style.transitionDuration = '0ms';
            scroll();
          }, duration);
        };

        scroll();
      }
    }
  }, []);

  if (!settings) {
    return <LoadingIndicator />;
  }

 

  

  
 
  return (
   
  

  <div className='md:container mx-auto' >
    { settings.active_slider == 1 && (
    <div className='container '>
          <MainSlider />
        </div>
    )}


    <section className=" container mb-[5px] xl:mb-[10px] mt-2 lg:pt-0">
      <div className="mx-auto md:w-[80%]">
        <div className="flex flex-col gap-y-[10px] xl:flex-row xl:gap-x-[30px]">
        <div className="news-ticker w-full text-left">
  {news && settings.active_news == 1 && (
   <div className="news-item-container h-30 w-full py-1 inline-block border border-gray-300 shadow-md p-2 overflow-hidden">

     <ScrollingText textArray={news} scrollSpeed={500} />
   
 </div>
  )}


          </div>
        </div>
      </div>
    </section>

  </div>
  );
};

export default Hero;
