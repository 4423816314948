import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const SearchForm = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimating(false);
    }, 1000);

    return () => clearTimeout(timeout);
  });

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value);
    performSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    performSearch(searchTerm);
  };

  const performSearch = (query) => {
    if (query.length > 0) {
      navigate(`/search?query=${query}`);
    } else {
      navigate(`/search?query=`);
      console.log('Please search for something');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`${isAnimating ? 'animate-shake' : 'animate-none'} w-full relative`}>
      <button className="btn btn-accent dark:bg-[#0C1D36] absolute h-11 top-0 left-0 rounded-tr-none rounded-br-none">
        <FiSearch className="text-xl dark:text-white" />
      </button>
      <input
        onChange={handleSearchInput}
        value={searchTerm}
        className="input h-11"
        type="text"
        placeholder="ابحث هنا .."
      />
    </form>
  );
};

export default SearchForm;
