import React from 'react';
import Marquee from 'react-fast-marquee';
import { motion } from 'framer-motion';
import './ScrollingText.css';

const ScrollingText = ({ textArray, duration }) => {

  let totalWidth = 0;

  for (const item of textArray) {
    const itemWidth = item.length * 16; // Assuming each character is 10 pixels wide
    totalWidth += itemWidth;
  }
 
  //const xMinValue = -Math.floor(totalWidth / 2);
  const xMinValue = totalWidth < 2020 ? -1020 : -Math.floor(totalWidth / 2);
  const xMaxValue = totalWidth < 2020 ? 1020 : Math.floor(totalWidth / 2);

  console.log('total width' + xMinValue + ' max value ' + xMaxValue);
  const marqueeVariants = {
    animate: {
      x: [xMinValue , xMaxValue ],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: 'loop',
          duration:30,
          ease: 'linear',
        },
      },
    },
  };

  return (
     <div className="scrolling-text-container">
      <motion.div className="scrolling-text-content" variants={marqueeVariants} animate="animate">
      {textArray?.map((item , index) => (
            <React.Fragment key={index}>
              <span className="scrolling-text-item dark:text-notdark">{item}</span>
              {index !== textArray.length - 1 && <span className="scrolling-text-item dark:text-notdark mx-5">|</span>}

            </React.Fragment>
          ))}
      </motion.div>
    </div>
  );
};

export default ScrollingText;
