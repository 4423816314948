import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../img/logo.png' ;
import Logo2 from '../img/logo2.png' ;
import { SettingsContext } from '../context/SettingsContext';

const Footer = () => {

   const settings = useContext(SettingsContext);

    
  if (!settings) {
   return <div></div>;
 }

  return(
    <div className=''>
     <section id="footer" className='  bg-accent md:px-18'>
           <div className='container mx-auto flex flex-col items-center justify-between md:px-40 py-6 mx-auto space-y-1 
            md-py-12 md:flex-row  md:space-y-0'>  
             <h2 className='px-6 md:text-2xl font-bold leading-light text-center text-xl text-white md:max-w-xl '>
                تواصل معنا في حال أردت الاستفسار أو طلب أي خدمة 
                   </h2>
             <div className='flex justify-center md:justify-start pt-2'>
            <a href={`https://wa.me/${settings.phone}`}  className='p-1 px-6 pt-2  text-black rounded-full
             bg-white shadow-2xl 
             hover:bg-gray-50 hover:text-accent' > اضغط هنا </a>
             </div>
           </div>
     </section>

     { /* backgound */}
     <div className=' container mx-auto md:px-30 py-1'>

    
         <div className='container flex flex-col-reverse justify-between px-6 py-1 mx-auto space-y-1
            md:flex-row md:space-y-0 '>

           <div className='flex flex-col-reverse  items-center justify-between  md:flex-col md:space-y-0 
            md:item-start '> 
          

            <Link to={'/'}>
        <img src={Logo} alt="wecard" className="w-28 dark:hidden" />
        <img src={Logo2} alt="wecard" className="w-28 hidden dark:block" />
      </Link>
      
            
            </div>

            <div>
               <div> 
                 <div className='text-center text-white dark:text-[#0C1D36] px-1 pt-1 md:px-6 md:pt-3 text-clip'>
                       موقع يختص بكل ما هو جديد في بطاقات الالعاب الاون لاين بجميع انواعها
                       <br/>
                       وبكل ما يخص تطبيقات السوشيال ميديا من محافظ الكترونية 

                 </div>

               </div>
            </div>
            
            <div className='text-center dark:text-[#0C1D36] px-1 pt-1 md:px-6 md:pt-3 '>

                           جميع الحقوق محفوظة لموقع ويكارد  &copy; 2023
            </div>
         </div>



      
     </div>



  </div>
  ) ;
};

export default Footer;
