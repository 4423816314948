import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

/* import Cart Provider  */
import CartProvider from './context/CartContext';
import SettingsContext from './context/SettingsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SettingsContext>
    <CartProvider>
    <App />
    </CartProvider>
    </SettingsContext>
  </React.StrictMode>
);
