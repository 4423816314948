import React from 'react';
import {Link} from 'react-router-dom' ; 
const Quta = ({item}) => {

  

      return  ( <div key={item.id}> 
                 <div className='flex-shrink-0 m-[1px] xl:m-2 cardbox 
                 rounded-[8px] overflow-hidden relative group pb-5 dark:bg-body'>

                    <div className='px-6 pb-8 flex flex-col'>
                      <div className='text-[15px] mb-2 lg:mb-1 mt-4 text-center font-bold text-[17px] '> { item.name} </div>
                      <div className='text-[15px] text-center  font-bold text-[17px] '> { item.price} 
                      
                      {item.currency ? `- ${item.currency}` : null}

                      
                      </div>

                    </div>
                    <Link to={`/product/${item.id}`} key={item.id} >
                    <div className=' bg-accent text-center mx-5 text-[17px] 
font-extrabold uppercase top-4 right-4 p-1 rounded-full z-10 '> اشتري الان </div>
                      </Link>
                 </div>
         </div>
      );

  
  
};

export default Quta;
