import React from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import useFetch from '../hooks/useFetch';
import Product from '../components/Product';
const Products = () => {

  const { id } = useParams();
  const { data } = useFetch(`/services/${id}`) ;
 // console.log(id + ' the id is ');
   return (   

    <div className='container flex flex-col sm:mx-auto min-h-[500px]   sm:mx-11 
    justify-right md:flex-row  h-full  gap-4 mb-2 xl:mb-[30px] 
    pt-[180px] lg:pt-0'>


    {data?.map((item) => (
    <Product key={item.id} item={item} />
  ))} 

    </div>
    
  
  )

 


  
  
};
export default Products;
