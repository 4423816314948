import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useFetch from '../hooks/useFetch';
import { SettingsContext } from '../context/SettingsContext';

const ProductDetails = () => {
  const { id } = useParams();
  const { data } = useFetch(`/quta/${id}`);
  const settings = useContext(SettingsContext);
  const [customerId, setCustomerId] = useState('');
  
  const [whatsappMessage, setWhatsappMessage] = useState('');

  useEffect(() => {
    if (data) {
      const message = `${customerId ? `Customer ID: ${customerId}\n` : ''}${data.service.name}\n\n${data.name}\n${data.price} - شيكل`;
      setWhatsappMessage(message);
    }
  }, [data, customerId]);

  const handleInputChange = (e) => {
    setCustomerId(e.target.value);
  };

  const handleSendMessage = () => {
    window.open(`https://wa.me/${settings.phone}?text=${encodeURIComponent(whatsappMessage)}`);
  };

  if (!settings) {
    return <div></div>;
  }

  if (!data) {
    return <div className='container mx-auto'>Loading...</div>;
  }

  return (
    <div className='mb-16 lg:pt-[10px] xl:pt-0'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-[30px] mb-[30px]'>
          <div className='flex-1 dark:border-gray-600 lg:max-w-[40%]  lg:h-[540px] bg-[#F7F7F7] rounded-lg flex justify-center items-center'>
            <img src={data.service.image.url} alt='' className='w-full max-w-[65%]' />
          </div>

          <div className='flex-1 bg-[#F7F7F7] dark:border-gray-600 p-12 xl:p-10 rounded-lg flex flex-col justify-center text-lg font-medium mb-2'>
            <div className='text-body text-lg font-medium mb-2'>
              <h2 className='h2 mb-4 text-body font-bold'>{data.name}</h2>
              <p className='mb-12'>
                {data.service.name} - {data.service.description}
              </p>
            </div>

            <div className='mt-4'>
              <label htmlFor='customerId' className='block mb-2 text-body font-bold'> 
 ادخل الـ ID الخاص بحسابك  👇 </label>
              <input
                id='customerId'
                type='text'
                placeholder='ID المشترك'
                value={customerId}
                onChange={handleInputChange}
                className='input w-full lg:w-[70%] m-auto'
              />
            </div>

            <div className='flex items-center gap-x-8 mt-4'>
              <div className='text-3xl text-body font-semibold'>{data.price}     {data.currency ? `- ${data.currency}` : null}</div>
              <button
                onClick={handleSendMessage}
                className='btn btn-accent text-[29px] max-w-md text-center'
              >
                <ion-icon name='logo-whatsapp'></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
