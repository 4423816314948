import React from 'react';

import {Swiper , SwiperSlide} from 'swiper/react'

import useFetch from '../hooks/useFetch';

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import '../slider.css'
import SwiperCore, { Pagination, Autoplay ,Navigation  } from 'swiper';

import CameraImg from '../img/camera.png' ;

/*
const sliderDate = [
  {
    img:CameraImg ,
    pretitle: 'Special offer' ,
    titlePart1 : 'Save 20%' ,
    titlePart2 : 'On Your' ,
    titlepart3 : 'first order' ,
    btnText : "shop Now"
  },
  {
    img:CameraImg ,
    pretitle: 'Special offer' ,
    titlePart1 : 'Save 20%' ,
    titlePart2 : 'On Your' ,
    titlepart3 : 'first order' ,
    btnText : "shop Now"
  },
  {
    img:CameraImg ,
    pretitle: 'Special offer' ,
    titlePart1 : 'Save 20%' ,
    titlePart2 : 'On Your' ,
    titlepart3 : 'first order' ,
    btnText : "shop Now"
  }

]
*/

const MainSlider = () => {
  const { data } = useFetch('/sliders');

  return (
    <Swiper
    modules={[Autoplay, Pagination, Navigation]}
      loop={true}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    
      className="mainSlide rounded-[8px] overflow-hidden drop-shadow-2xl w-full 
      md:w-[80%] h-[10rem] md:h-[16rem]"
      containerClass="swiper-container" // Add the custom class to the container
  wrapperClass="swiper-wrapper" 
    >
      {data?.map((slide) => (
       <SwiperSlide key={slide.id} className="flex items-center justify-center">
       <div className="h-[10rem] md:h-[16rem]">
         <img src={slide.image.url} alt="" className="w-full h-full object-cover " />
       </div>
     </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MainSlider;