import React from 'react';
import ProductSlider from './ProductSlider';
import useFetch from '../hooks/useFetch.js';
import Product from '../components/Product';

const LatestProducts = () => {
  const { data } = useFetch('/services');

  return (
    <div className="md:container mx-auto mb-10">
    <div className="px-2 lg:px-32 flex flex-wrap items-right justify-right">
      {data?.map((item, index) => (
        <div key={item.id} className={`w-1/3 sm:w-1/3 md:w-1/4 lg:w-1/5 `} >
          <Product item={item} />
        </div>
      ))}
    </div>
  </div>
    /*
    <div className="mx-auto mb-10">
      <div className="px-4 lg:px-24 flex flex-wrap items-right justify-right">
        {data?.map((item) => (
          <div key={item.id} className="mb-4">
              <Product item={item}/>
          </div>
        ))}
      </div>
    </div>*/
  );
  
  
};

export default LatestProducts;
