import React from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Product = ({ item }) => {
  const isActive = item.active;

  const handleClick = () => {
    if (!isActive) {
      toast.error(' غير متوفر حاليا 🦄', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        rtl:true ,
        progress: undefined,
        theme: "light",
        });
    }
  };

  return (
    <div key={item.id}>
      
     
      {isActive ? (
        <Link to={`/quotes/${item.id}`} key={item.id}>
       <div
          className={`flex-shrink-0 m-1 xl:m-2 relative overflow-hidden 
          rounded-lg max-w-xs shadow-md transition-all md:duration-300 md:transform md:hover:-translate-y-2 ${
            isActive ? 'hover:shadow-lg' : ''
          }`}
          style={{
            background: isActive ? (item.color || '#F6CD46') : 'slategray',
            filter: isActive ? 'none' : 'brightness(50%)',
          }}
        >
          {!isActive && (
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-white text-lg font-semibold"></span>
            </div>
          )}
          <div className="relative pt-2 px-2 flex items-center justify-center">
            <div className="block absolute w-48 h-40 bottom-0 left-0 -mb-24 ml-3" style={{ background: 'radial-gradient(black, transparent 60%)', transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)', opacity: 0.2 }}></div>
            <img className="relative w-48 h-30 md:h-40   transition-all duration-300" src={item.image.original_url} alt="" />
          </div>
          <div className="relative text-white px-1 pb-0 mt-1  -mx-1">
            <div className="flex justify-center">
            <span className="block bg-white rounded-lg w-full text-center text-orange-500 text-md font-bold
             px-1 py-3 leading-none shadow-md border border-gray-300">
              {item.name}
            </span>
            </div>
          </div>
        </div>
        </Link>
      ) : (
        <div onClick={handleClick}>
         <div
          className={`flex-shrink-0 m-1 xl:m-2 relative overflow-hidden rounded-lg max-w-xs shadow-md 
          transition-all md:duration-300 md:transform hover:-translate-y-2 ${
            isActive ? 'hover:shadow-lg' : ''
          }`}
          style={{
            background: isActive ? (item.color || '#F6CD46') : 'slategray',
            filter: isActive ? 'none' : 'brightness(50%)',
          }}
        >
          {!isActive && (
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-white text-lg font-semibold"></span>
            </div>
          )}
          <div className="relative pt-2 px-2 flex items-center justify-center">
            <div className="block absolute  bottom-0 left-0 -mb-24 ml-3" style={{ background: 'radial-gradient(black, transparent 60%)', transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)', opacity: 0.2 }}></div>
            <img className="relative w-48 h-30 md:h-40  transition-all duration-300" src={item.image.original_url} alt="" />
          </div>
          <div className="relative text-white px-1 pb-1 mt-2">
            <div className="flex justify-center">
            <span className="block bg-white rounded-full w-full text-center text-orange-500 text-md font-bold
             px-1 py-3 leading-none shadow-md border border-gray-300">
              {item.name}
            </span>
            </div>
          </div>
        </div>
        </div>
      )}
    </div>

        
      
  
  );
};

export default Product;
