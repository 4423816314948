import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SlBag } from 'react-icons/sl';
import { FiMenu, FiSearch, FiX } from 'react-icons/fi';

import Logo from '../img/logo.png';
import Logo2 from '../img/logo2.png';

import SearchForm from '../components/SearchForm';
import CategoryNavMobile from '../components/CategoryNavMobile';
import Cart from '../components/Cart';

import { CartContext } from '../context/CartContext';
import { SettingsContext } from '../context/SettingsContext';

const Header = () => {
  const { isOpen, setIsOpen } = useContext(CartContext);
  const settings = useContext(SettingsContext);
  const [catNavMobile, setCatnavMobile] = useState(false);
  const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const shouldShowShadow = scrollPosition > 0;
      setShowShadow(shouldShowShadow);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const element = document.documentElement;
    if (darkMode) {
      element.classList.add('dark');
    } else {
      element.classList.remove('dark');
    }
    localStorage.setItem('theme', darkMode ? 'dark' : 'notdark');
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleSearchIconClick = () => {
    if (window.innerWidth <= 768) {
      setShowMobileSearch(true);
    }
  };

  const handleSearchClose = () => {
    if (window.innerWidth <= 768) {
      setShowMobileSearch(false);
    }
  };

  if (!settings) {
    return <div></div>;
  }

  return (
    <header className="sticky top-0 z-40 bg-primary dark:bg-slate py-1 xl:mb-[10px]">
      <div className="container mx-auto">
        <div className="flex flex-row gap-4 lg:items-center justify-between mb-1 xl:mb-0">
          <div className={`${catNavMobile ? 'right-0' : '-right-full'} fixed top-0 bottom-0 z-30 w-full h-screen transition-all duration-200`}>
            <CategoryNavMobile setCatnavMobile={setCatnavMobile} />
          </div>
          <div className="flex items-center">
            <Link to={'/'}>
              <img src={Logo} alt="wecard" className="max-w-[75px] dark:hidden" />
              <img src={Logo2} alt="wecard" className="max-w-[75px] hidden dark:block" />
            </Link>

          </div>

          
          {window.innerWidth > 768 && (
               <div className='hidden w-full xl:flex xl:max-w-[534px] '>
               <SearchForm />
             </div>
            )}

          
          <div className="flex items-center gap-x-[10px] dark:text-notdark">

          {window.innerWidth <= 768 && (
              <div className="ml-2">
                {showMobileSearch ? (
                  <button onClick={handleSearchClose} className="text-white dark:text-notdark">
                    <FiX size={24} />
                  </button>
                ) : (
                  <button onClick={handleSearchIconClick} className="text-white dark:text-notdark">
                    <FiSearch size={28} />
                  </button>
                )}
              </div>
            )}

        

          <button onClick={toggleDarkMode} className={`leading-9 text-[29px]  rounded-full m-1 ${darkMode ? 'text-notdark' : ''}`}>
             
                <ion-icon name="sunny" size={28} ></ion-icon>
              </button>

            <div className="hidden xl:flex uppercase">
              <span className="font-bold ml-2">تواصل معنا</span>
              <span dir="ltr" className="font-bold">
                <a href={`https://wa.me/${settings.phone}`}>{settings.phone}</a>
              </span>
            </div>
            <div className="xl:hidden rounded">
              <button className={`leading-9 text-[29px] ml-7 rounded-full m-1 ${darkMode ? 'text-notdark' : ''}`}>
                <ion-icon name="logo-whatsapp" size={28}></ion-icon>
              </button>
            </div>
          </div>
        </div>
        {/* SearchForm show in mobile only*/}
        {showMobileSearch && (
          <div className="xl:hidden">
            <SearchForm />
          </div>
        )}
      </div>
      {showShadow && <div className="absolute left-0 bottom-0 w-full h-3 bg-gradient-to-t dark:from-gray-200 from-gray-700 via-transparent to-transparent"></div>}
    </header>
  );
};

export default Header;
